import ChatbotIcon from "@/assets/default_chatbot.svg";
import {
  DeleteDropDownContainer,
  DropDownButton,
} from "@/components/keyPad.style";
import Checkbox from "@/components/shared/Checkbox";
import {
  deleteDropdownContainer,
  footerDropdownItem,
  headerDropdown,
} from "@/components/shared/Dropdown.style";
import { replaceEmojis, replaceReactionEmoji } from "@/utils/helpers/emoji";
import { getFileContentTypeHumanReadableName } from "@/utils/helpers/fileUtils";
import { ls } from "@/utils/helpers/localstorage";
import { useDeleteConversationModal } from "@/utils/hooks/useDeleteConversationModal";
import {
  getSelectedConversationId,
  setSelectedConversationId,
} from "@/utils/messaging/conversation/ConversationState";
import { getConversationTitle } from "@/utils/messaging/conversation/conversationUtils/getConversationTitle";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import { sanitizeMessagePreview } from "@/utils/messaging/sanitize";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { Reorder } from "motion/react";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useSnapshot } from "valtio/react";
import { Avatar } from "../../components/shared/Avatar";
import {
  editListItemStyle,
  editListItemStyleDisabled,
  listItemStyle,
} from "../../components/shared/ListItem.style";
import ToolTip from "../../components/shared/Tooltip";
import { colors } from "../../styles/global.styles";
import { useTempProxy } from "../../utils";
import WebGwContact from "../../utils/helpers/WebGwContact";
import {
  convertDateToHumanReadable,
  convertDateToHumanReadableShort,
} from "../../utils/helpers/time";
import NmsMessage from "../../utils/messaging/NmsMessage";
import Conversation from "../../utils/messaging/conversation/Conversation";
import { getThumbnailImageFromNmsMessage } from "../../utils/messaging/parseNmsToChatMessage";

export default function ConversationItem({
  ref,
  lastInMessage,
  lastMessage: lastMessageProxy,
  contact,
  enableChatScreen,
  conversation,
  isEditing,
  noNameGroupChatPosition,
  selectedConversationsCheckboxes,
  onSelectedCheckboxConversation,
  onSelectConversation,
}: {
  lastInMessage: NmsMessage | undefined;
  lastMessage: NmsMessage;
  contact: WebGwContact | undefined;
  enableChatScreen: () => void;
  conversation: Conversation;
  isEditing: boolean;
  noNameGroupChatPosition: number | undefined;
  selectedConversationsCheckboxes: string[];
  onSelectedCheckboxConversation: (
    conversation: Conversation,
    selected: boolean
  ) => void;
  onSelectConversation: (conversationId: string, fn: VoidFunction) => void;
} & {
  ref?: React.RefObject<HTMLLIElement>;
}) {
  const lastMessage = useSnapshot(lastMessageProxy);

  const tempProxy2 = useTempProxy({
    isDisplayedNotificationSent: true,
  } as NmsMessage);
  const { isDisplayedNotificationSent } = useSnapshot(
    lastInMessage || tempProxy2
  );
  const isRead = lastMessage.Direction === "Out" || isDisplayedNotificationSent;

  const hideDropdown = () => {
    setDropdownOpen(false);
  };

  const showDropdown = () => {
    setDropdownOpen(true);
  };

  const { conversationTitle, isChatbot } = getConversationTitle(
    conversation,
    noNameGroupChatPosition
  );
  const { modal: deleteConversationModal, show: showDeleteConversationModal } =
    useDeleteConversationModal(conversation, conversationTitle, hideDropdown);

  const handleMessageItemSelected = (
    e: React.MouseEvent | React.TouchEvent
  ) => {
    if (import.meta.env.DEV) {
      console.debug("conversationId", conversation.id, "selected");
      if (e.ctrlKey) {
        console.log(conversation);
        return;
      }
    }

    onSelectConversation(conversation.id, () => {
      setSelectedConversationId(conversation.id);
      enableChatScreen();
    });
  };

  const isFile = !!(
    lastMessage &&
    (lastMessage.FileName ||
      lastMessage.payloadParts.some((part) =>
        part.contentDisposition?.startsWith("attachment")
      ))
  );

  const [thumbnailLoadError, setThumbnailLoadError] = useState(false);

  const thumbnail = useMemo(
    () =>
      isFile
        ? getThumbnailImageFromNmsMessage(
            lastMessage,
            lastMessageProxy,
            thumbnailLoadError
          )
        : undefined,
    [lastMessage, thumbnailLoadError]
  );

  useLayoutEffect(() => {
    if (thumbnailLoadError) {
      setThumbnailLoadError(false);
    }
  }, [thumbnail?.thumbnailUrl]);

  const messageHTML = useMemo(() => {
    if (lastMessageProxy.deleted) {
      switch (lastMessageProxy.Direction) {
        case "In": {
          const senderName = conversation.participants
            .find((contact) =>
              isSamePhoneNumber(
                contact.getMainPhoneNumber(),
                lastMessageProxy.From
              )
            )
            ?.noNameReturnPhoneNumber();
          return `${senderName || "User"} removed a message`;
        }
        case "Out":
          return "You removed a message";
      }
    }

    const reactionImage =
      conversation.lastMessageReaction &&
      replaceReactionEmoji(conversation.lastMessageReaction.reaction);
    let contactName: string | undefined = "";

    if (conversation.getIsGroupChat()) {
      if (conversation.lastMessageReaction) {
        if (
          isSamePhoneNumber(conversation.lastMessageReaction.from, ls.getUser())
        ) {
          contactName = "You";
        } else {
          contactName = WebGwContact.fromPhoneNumber(
            conversation.lastMessageReaction.from,
            true
          )?.noNameReturnPhoneNumber();
        }
      } else if (lastMessageProxy.Direction === "In") {
        const sender =
          (lastMessageProxy.contact() as WebGwContact) ||
          WebGwContact.fromPhoneNumber(lastMessageProxy.From);
        contactName = `${sender.noNameReturnPhoneNumber()}`;
      }
    }

    let message = getFileContentTypeHumanReadableName(lastMessageProxy);

    if (message) {
      if (conversation.getIsGroupChat()) {
        if (conversation.lastMessageReaction) {
          message = `${contactName} reacted ${reactionImage} to ${message}`;
        } else {
          message = `${contactName || "You"} sent ${message}`;
        }
      } else {
        if (conversation.lastMessageReaction) {
          if (
            isSamePhoneNumber(
              conversation.lastMessageReaction.from,
              ls.getUser()
            )
          ) {
            message = `You reacted ${reactionImage} to ${message}`;
          } else {
            message = `Reacted ${reactionImage} to ${message}`;
          }
        } else {
          message = `You ${lastMessageProxy.Direction === "In" ? "received" : "sent"} ${message}`;
        }
      }
    } else if (isChatbot && lastMessageProxy.Direction === "In") {
      message = "You received a text.";
    } else {
      let text = lastMessageProxy?.getText();
      if (text) {
        [text] = replaceEmojis(text);
        text = sanitizeMessagePreview(text);

        if (conversation.getIsGroupChat()) {
          if (conversation.lastMessageReaction) {
            message = `${contactName} reacted ${reactionImage}: ${text}`;
          } else {
            message = `${contactName || "You"}: ${text}`;
          }
        } else {
          if (conversation.lastMessageReaction) {
            if (
              isSamePhoneNumber(
                conversation.lastMessageReaction.from,
                ls.getUser()
              )
            ) {
              message = `You reacted ${reactionImage}: ${text}`;
            } else {
              message = `Reacted ${reactionImage}: ${text}`;
            }
          } else {
            message = text;
          }
        }
      }
    }

    return message;
  }, [lastMessage, conversation.lastMessageReaction]);

  const handleCheckboxChange = (conversation: Conversation, value: boolean) => {
    onSelectedCheckboxConversation(conversation, !value);
  };

  const [_, setPosition] = useState({ top: 0, left: 0 });
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const conversationForDropDownRef = useRef<HTMLDivElement>(null);

  const handleRightClick = (event: MouseEvent) => {
    event.preventDefault();
    const screenW = window.innerWidth;
    const right = event.clientX + 175 > screenW;
    const finalClientX = right ? event.clientX - 175 : event.clientX;

    setPosition({ top: event.clientY, left: finalClientX });
    showDropdown();
    event.stopPropagation();
  };

  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  useOnClickOutside(conversationForDropDownRef, () => hideDropdown());

  const toggleDeleteOverlay = () => {
    setDropdownOpen(false);
    showDeleteConversationModal();
  };

  if (!conversationTitle) {
    return null;
  }

  return (
    <Reorder.Item
      // value is never used because onReorder is not used in the Reorder.Group parent component
      value={null}
      drag={false}
      css={[
        listItemStyle,
        { cursor: "default", position: "relative", overflow: "visible" },
      ]}
      ref={ref}
      key={conversation.id}
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.25 }}
      onContextMenu={handleRightClick}
      onClick={() => {
        if (isEditing) {
          handleCheckboxChange(
            conversation,
            selectedConversationsCheckboxes.includes(conversation.id)
          );
        }
      }}
    >
      {isEditing && (
        <Checkbox
          checkboxId={conversation.id}
          disabled={conversation._isBeingDeleted}
          checked={selectedConversationsCheckboxes.includes(conversation.id)}
        />
      )}
      <div
        css={[
          editListItemStyle,
          conversation._isBeingDeleted && editListItemStyleDisabled,
        ]}
        style={{
          cursor: conversation._isBeingDeleted ? "default" : "pointer",
          width: "100vw",
          backgroundColor:
            getSelectedConversationId() === conversation.id
              ? colors.secondaryBackground
              : undefined,
        }}
        onMouseDown={handleMessageItemSelected}
        onTouchStart={handleMessageItemSelected}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            userSelect: "none",
            gap: "1em",
            position: "relative",
          }}
        >
          {!isRead && (
            <div
              css={{
                borderRadius: "100%",
                backgroundColor: colors.primaryAccentColor,
                width: "8px",
                height: "8px",
                position: "absolute",
                transform: "translateX(calc(-50% - 0.5em))",
              }}
            ></div>
          )}
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: "fit-content",
              opacity: "1",
              filter: "grayscale(0)",
              transition: "all 0.5s ease-out",
            }}
            style={
              conversation._isBeingDeleted
                ? { opacity: 0.25, filter: "grayscale(1)" }
                : undefined
            }
          >
            <Avatar
              contact={contact}
              isGroupChat={conversation.getIsGroupChat()}
              photo={conversation.getIconUrl()}
            />
            {isChatbot ? (
              <img
                css={{
                  background: "#303030",
                  borderRadius: "50%",
                  width: "12px",
                  height: "12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "0px",
                  right: "0px",
                  padding: "2px",
                  boxSizing: "content-box",
                  overflow: "hidden",
                }}
                src={ChatbotIcon}
              />
            ) : (
              ""
            )}
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "0.25em",
              width: "100%",
              flexShrink: "1",
              flexGrow: "0",
            }}
          >
            <div
              css={{
                color: colors.primaryTextColor,
                maxWidth: "16em",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {conversationTitle}
            </div>

            {messageHTML && (
              <div
                css={{
                  color: colors.secondaryTextColor,
                  fontSize: "0.8em",
                  maxWidth: "18em",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                style={
                  lastMessage.deleted
                    ? {
                        opacity: "0.75",
                        fontStyle: "italic",
                      }
                    : undefined
                }
                // eslint-disable-next-line @eslint-react/dom/no-dangerously-set-innerhtml
                dangerouslySetInnerHTML={{ __html: messageHTML }}
              ></div>
            )}
          </div>
        </div>

        {lastMessage?.Date && (
          <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {!lastMessage.deleted &&
              (thumbnail && !thumbnailLoadError ? (
                <img
                  css={{
                    height: "100%",
                    width: "100%",
                    maxHeight: "3em",
                    maxWidth: "3em",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                  src={thumbnail.thumbnailUrl}
                  alt={thumbnail.contentDisposition}
                  onError={() => setThumbnailLoadError(true)}
                />
              ) : (
                isFile && (
                  <AttachFileIcon
                    css={{
                      maxHeight: "3em",
                      maxWidth: "3em",
                      color: colors.primaryTextColor,
                    }}
                  />
                )
              ))}
            <MessageTime lastMessageTime={lastMessage.Date} />
          </div>
        )}
      </div>
      {isDropdownOpen && (
        <DeleteDropDownContainer
          style={{
            margin: "0.5em 0",
            position: "absolute",
            top: "10%",
            right: "4%",
          }}
          css={[deleteDropdownContainer, headerDropdown]}
          ref={conversationForDropDownRef}
        >
          <DropDownButton
            css={[
              footerDropdownItem,
              {
                fontSize: "0.8em",
              },
            ]}
            onClick={toggleDeleteOverlay}
          >
            <DeleteIcon css={{ fontSize: "1em" }} /> Delete
          </DropDownButton>
        </DeleteDropDownContainer>
      )}
      {deleteConversationModal}
    </Reorder.Item>
  );
}

function MessageTime({ lastMessageTime }: { lastMessageTime: Date }) {
  return (
    <ToolTip
      tooltipContent={<>{convertDateToHumanReadable(lastMessageTime)}</>}
      color={colors.primaryTextColor}
      backgroundColor={colors.tertiaryTextColor}
      css={{
        color: colors.secondaryTextColor,
        fontSize: "0.75em",
        whiteSpace: "nowrap",
        zIndex: "initial",
      }}
    >
      {convertDateToHumanReadableShort(lastMessageTime)}
    </ToolTip>
  );
}
